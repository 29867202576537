import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue} from 'bootstrap-vue/dist/bootstrap-vue.esm';

import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'bootstrap/dist/css/bootstrap.css';
import "./css/main.css"
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from "./router";
import VueMeta from 'vue-meta'
import VueCookies from 'vue-cookies'

Vue.use(VueMeta)
Vue.use(VueCookies, { expires: '14d'})

Vue.use(BootstrapVue);

Vue.use(VueAxios, axios)
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
