<template>
  <div v-if="data">
    <div class="loader" v-if="isLoading">
      <b-spinner variant="primary" style="width: 60px; height: 60px"></b-spinner
      ><br />
      Ładowanie danych...
    </div>
    <div v-if="thankyou && data && !isLoading">
      <header v-if="!isLoading && data" class="shadow3">
        <b-container>
          <b-row>
            <div class="logos-container shadow3">
              <a :href="data.foundationWebUrl" target="_blank">
                <img alt="Donateo" :src="data.foundationLogoUrl"
              /></a>
            </div>
            <b-col class="">
              <b-navbar toggleable="xl" class="header-menu">
                <b-navbar-toggle
                  target=""
                  style="position: absolute; right: 0"
                  :class="visible ? null : 'collapsed'"
                  :aria-expanded="visible ? 'true' : 'false'"
                  @click="visible = !visible"
                  ><img
                    alt="Donateo"
                    src="./assets/menu.svg"
                  />MENU</b-navbar-toggle
                >

                <b-collapse id="nav-collapse" v-model="visible" is-nav>
                  <b-navbar-nav>
                    <b-nav-item href="#targets" @click="scrollMeTo('targets')"
                      >Wspierane cele</b-nav-item
                    >
                    <b-nav-item href="#about" @click="scrollMeTo('about')"
                      >O fundacji</b-nav-item
                    >
                    <b-nav-item href="#why" @click="scrollMeTo('why')"
                      >Dlaczego warto</b-nav-item
                    >
                    <b-nav-item
                      href="#howitworks"
                      @click="scrollMeTo('howitworks')"
                      >Jak to działa</b-nav-item
                    >
                    <!--<b-nav-item href="#opinions" @click="scrollMeTo('opinions')"
                      >Opinie</b-nav-item
                    >-->
                    <b-nav-item href="#faq" @click="scrollMeTo('faq')"
                      >FAQ</b-nav-item
                    >
                    <b-nav-item
                      :href="data.chromeExtensionUrl"
                      @click="thankyou = true"
                      target="_blank"
                    >
                    <button class="sunseason" v-if="data.subKey == 'sunseasons'">
                      Apartamenty Karpacz
                      </button>

                      <button class="solid chrome" v-else>
                        <img alt="Donateo" src="./assets/chrome.svg" />Dodaj
                        Altroo do Chrome
                      </button>
                    </b-nav-item>
                  </b-navbar-nav>

                  <!-- Right aligned nav items -->
                </b-collapse>
              </b-navbar>
            </b-col>
          </b-row>
        </b-container>
      </header>

      <div class="hero"  :class="data.subKey == 'sunseasons' ? 'sunseasons' : ''">
        <b-container>
          <div
            class="card"
            style="
              padding: 30px;
              box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.08);
              border: 0;
            "
          >
            <b-row align-v="center">
              <b-col lg="5" md="12" class="text-center">
                <h1>Dziękujęmy<br />za Twoje wsparcie!</h1>
                <p>
                  Razem możemy zdziałać więcej.<br />Podziel się informacją o
                  naszej akcji ze znajomymi.
                </p>
                <div class="socials button-group">
                  <a
                    :href="data.facebook"
                    v-if="data.facebook != null"
                    target="_blank"
                    ><img alt="Facebook" src="./assets/facebook.svg"
                  /></a>
                  <a
                    :href="data.instagram"
                    v-if="data.instagram != null"
                    target="_blank"
                    ><img alt="Instagram" src="./assets/instagram.svg"
                  /></a>
                  <a
                    :href="data.twitter"
                    v-if="data.twitter != null"
                    target="_blank"
                    ><img alt="Twitter" src="./assets/twitter.svg"
                  /></a>
                  <a
                    :href="data.linkedIn"
                    v-if="data.linkedIn != null"
                    target="_blank"
                    ><img alt="Linkedin" src="./assets/linkedin.svg"
                  /></a>
                </div>
                <div class="button-group">
                  <a href="#" @click="thankyou = false">
                    <button class="">Wróć do strony głównej</button>
                  </a>
                </div>
              </b-col>
              <b-col>
                <div v-if="!isLoading">
                  <LiteYouTubeEmbed
                    :title="data.foundationName"
                    :id="data.foundationDescriptionYoutubeUrl"
                  /></div
              ></b-col>
            </b-row>
          </div>
        </b-container>
      </div>
    </div>
    <div v-else :class="data.subKey == 'sunseasons' ? 'sunseasons' : ''">
      <header v-if="!isLoading && data" class="shadow3">
        <b-container>
          <b-row>
            <div class="logos-container shadow3">
              <a :href="data.foundationWebUrl" target="_blank">
                <img alt="Donateo" :src="data.foundationLogoUrl"
              /></a>
            </div>
            <b-col class="">
              <b-navbar toggleable="xl" class="header-menu">
                <b-navbar-toggle
                  target=""
                  style="position: absolute; right: 0"
                  :class="visible ? null : 'collapsed'"
                  :aria-expanded="visible ? 'true' : 'false'"
                  @click="visible = !visible"
                  ><img
                    alt="Donateo"
                    src="./assets/menu.svg"
                  />MENU</b-navbar-toggle
                >

                <b-collapse id="nav-collapse" v-model="visible" is-nav>
                  <b-navbar-nav>
                    <b-nav-item href="#targets" @click="scrollMeTo('targets')"
                      >Wspierane cele</b-nav-item
                    >
                    <b-nav-item href="#about" @click="scrollMeTo('about')"
                      >O fundacji</b-nav-item
                    >
                    <b-nav-item href="#why" @click="scrollMeTo('why')"
                      >Dlaczego warto</b-nav-item
                    >
                    <b-nav-item
                      href="#howitworks"
                      @click="scrollMeTo('howitworks')"
                      >Jak to działa</b-nav-item
                    >
                    <!--<b-nav-item href="#opinions" @click="scrollMeTo('opinions')"
                      >Opinie</b-nav-item
                    >-->
                    <b-nav-item href="#faq" @click="scrollMeTo('faq')"
                      >FAQ</b-nav-item
                    >
                    <b-nav-item
                      :href="data.chromeExtensionUrl"
                      @click="thankyou = true"
                      target="_blank"
                    >
                    <button class="sunseason" v-if="data.subKey == 'sunseasons'">
                      Apartamenty Karpacz
                      </button>

                      <button class="solid chrome" v-else>
                        <img alt="Donateo" src="./assets/chrome.svg" />Dodaj
                        Altroo do Chrome
                      </button>
                    </b-nav-item>
                  </b-navbar-nav>

                  <!-- Right aligned nav items -->
                </b-collapse>
              </b-navbar>
            </b-col>
          </b-row>
        </b-container>
      </header>

      <div class="hero" :class="data.subKey == 'sunseasons' ? 'sunseasons' : ''">
        <b-container>
          <b-row align-v="center">
            <b-col xl="6" lg="7" md="12">
              <h1>
                {{ data.foundationTtle }}
              </h1>
              <p>
                {{ data.heroHeader }}
              </p>
              <div class="button-group">
                <a
                  :href="data.chromeExtensionUrl"
                  @click="thankyou = true"
                  target="_blank"
                >
                <button class="sunseason solid" v-if="data.subKey == 'sunseasons'">
                      Apartamenty Karpacz
                      </button>

                      <button class="solid chrome" v-else>
                        <img alt="Donateo" src="./assets/chrome.svg" />Dodaj
                        Altroo do Chrome
                      </button>
                  
                </a>
                <a href="#howitworks" @click="scrollMeTo('howitworks')">
                  <button class="">Zobacz jak to działa</button>
                </a>
                <p style="font-size: 14px; margin-top: 10px;" v-if="data.subKey != 'sunseasons'">Po kliknięciu zostaniesz przeniesiony do Chrome Web Store</p>
              </div>
              <p v-if="data.subKey != 'sunseasons'">
                <strong
                  >Altroo pobrano:
                  <span class="h2">{{ data.altrooDownloadsCount }}</span>
                  razy</strong
                >
              </p>
            </b-col>
            <b-col><img alt="Donateo" :src="data.topBannerUrl" /></b-col>
          </b-row>
        </b-container>
      </div>

      <div v-if="!showBlog">

      <section ref="targets" v-if="data.fundrises">
        <b-container>
          <h2 class="text-center" v-if="data.subKey == 'sunseasons'">Kogo wspieramy</h2>
          <h2 class="text-center" v-else>Wybierz cel, który chcesz wspierać</h2>
          
          <div
            class="fundraise-container"
            :class="data.subKey == 'sunseasons' ? 'sunseasons' : ''"
            v-for="item in data.fundrises"
            :key="item.id"
          >
            <b-row align-v="center">
              <b-col md="5" sm="12">
                <div
                  class="fundraise-image shadow2"
                  v-bind:style="{
                    'background-image': 'url(' + item.imageUrl + ')',
                  }"
                ></div>
              </b-col>
              <b-col md="7" sm="12">
                <div style="padding: 20px">
                  <h3>{{ item.title }}</h3>
                  <p>
                    {{ item.description }}
                  </p>
                  
                  <h6 v-if="data.subKey != 'sunseasons'">Zebrana kwota:</h6 >
                  <b-row>
                    <b-col md="12" lg="7" v-if="data.subKey != 'sunseasons'">
                      <div class="fundraise-counter">
                        <div class="fundraise-counter--count">
                          <h3>{{ item.currentAmount }} zł</h3>
                        </div>
                        <div class="fundraise-counter--goal text-right">
                          Cel: {{ item.successAmount }} zł
                        </div>
                      </div>
                      <b-progress
                        height="12px"
                        style="border-radius: 12px; margin-top: -20px"
                        show-progress
                        class="mb-2"
                        :max="max"
                        aria-label="progress"
                      >
                        <b-progress-bar
                          label=" "
                          :value="
                            calcPercentage(
                              item.currentAmount,
                              item.successAmount
                            )
                          "
                        ></b-progress-bar>
                      </b-progress>
                    </b-col>
                    <b-col md="12" lg="7" v-else>
                      <div class="fundraise-counter--goal">Partner akcji przekaże <strong>5%</strong> wartości wynajmowanego apartamentu na rzecz Fundacji 2xKochaj.</div>
                      </b-col>
                    <b-col md="12" lg="5">
                      <a
                        :href="data.chromeExtensionUrl"
                        @click="thankyou = true"
                        target="_blank"
                      >
                      <button class="sunseason solid" v-if="data.subKey == 'sunseasons'">
                      Apartamenty Karpacz
                      </button>

                      <button class="solid" v-else>Dodaj
                        Altroo do Chrome
                      </button>
                      </a>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>
          </div>
          <p class="text-center mt-5" v-if="data.foundationWardsUrl">
            <a :href="data.foundationWardsUrl" target="_blank"
              ><button>Zobacz komu jeszcze pomagamy</button></a
            >
          </p>
        </b-container>
      </section>
      <b-container>
        <hr />
      </b-container>

      <section ref="about">
        <b-container>
          <b-row align-v="center">
            <b-col md="4" sm="12">
              <h2>{{ data.foundationName }}</h2>
              <p>
                {{ data.foundationShortDescription }}
              </p>
              <a :href="data.foundationMissionUrl" target="_blank"
                ><button style="margin-bottom: 20px">Nasza misja</button></a
              >
            </b-col>
            <b-col md="8" sm="12">
              <div class="video-container" v-if="!isLoading">
                <LiteYouTubeEmbed
                  :title="data.foundationName"
                  :id="data.foundationDescriptionYoutubeUrl"
                />
              </div>
            </b-col>
          </b-row>
        </b-container>
      </section>

      <section class="why-altroo sunseason" style="margin-top: 80px" ref="why" v-if="data.subKey == 'sunseasons'">
        <b-container v-if="data.whyIsWorthItItems">
          <b-row class="text-center">
            <b-col>

              <img v-if="data.subKey == 'sunseasons'"
                alt="Donateo"
                src="./assets/ss24logo.svg"
                style="margin-top: 80px; margin-bottom: 24px"
              />


              <h2  style="margin-bottom: 71px">Dlaczego warto pomagać wynajmując apartamenty w Karpaczu</h2>
              

            </b-col>
          </b-row>
          <b-row  v-if="data.whyIsWorthItItems">
            <b-col lg="6" md="12">
              <img alt="Donateo" src="./assets/why-ss.png" />
              
            </b-col>
            <b-col>
              <p v-html="data.whyIsWorthItItems[0].description"></p>
              <button class="sunseason">
                Zobacz jak pomagamy
                      </button>
            </b-col>
          </b-row>
        </b-container>
      </section>

      <section class="why-altroo" style="margin-top: 80px" ref="why" v-else>
        <b-container>
          <b-row class="text-center">
            <b-col>


              <img 
                alt="Donateo"
                src="./assets/altroo.svg"
                style="margin-top: 80px; margin-bottom: 24px"
              />

              <h2 style="margin-bottom: 71px">
                Dlaczego warto pomagać korzystając<br />z przeglądarki Altroo
              </h2>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="7" md="12">
              <div
                v-for="item in data.whyIsWorthItItems"
                :key="item.title"
                class="ac-item"
                :class="item.isOpen ? 'open shadow3' : null"
              >
                <div
                  class="ac-item--header"
                  :class="item.isOpen ? null : 'collapsed'"
                  :aria-expanded="item.isOpen ? 'true' : 'false'"
                  :aria-controls="item.title"
                  @click="item.isOpen = !item.isOpen"
                >
                  <span class="arrow"
                    ><img alt="Donateo" src="./assets/arrow.svg"
                  /></span>
                  <h5>{{ item.title }}</h5>
                </div>
                <b-collapse :id="item.title" v-model="item.isOpen" class="mt-2">
                  <div class="ac-item--body">{{ item.description }}</div>
                </b-collapse>
                <hr v-if="!item.isOpen" />
              </div>
            </b-col>
            <b-col><img alt="Donateo" src="./assets/why-map.webp" /></b-col>
          </b-row>
        </b-container>
      </section>

      <section v-if="data.subKey == 'sunseasons'">
        <b-container class="text-center" ref="howitworks">
          <b-row>
            <b-col>
              <h2  style="margin-bottom: 80px">3 proste kroki, by pomóc</h2>
              
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4" sm="12">
              <div class="step">
                <img alt="Donateo" src="./assets/step1.svg" />
                <h4>Kliknij w link</h4>
                <p>Kliknij w link - apartamenty Karpacz  i sprawdź bogatą ofertę lokali, które uczynią Twój wyjazd jeszczę bardziej wyjątkowym.</p>
              </div>
            </b-col>
            <b-col md="4" sm="12">
              <div class="step">
                <img alt="Donateo" src="./assets/step2.svg" />
                <h4>Wybierz apartament </h4>
                <p>Przejrzyj dostępne apartamenty i wybierz ten, który najlepiej pasuje do Twoich potrzeb oraz osób, z którymi podróżujesz.</p>
              </div>
            </b-col>
            <b-col md="4" sm="12">
              <div class="step">
                <img alt="Donateo" src="./assets/step3.svg" />
                <h4>Zarezerwuj
                </h4>
                <p>Część środków z Twojej rezerwacji trafi do Fundacji 2xKochaj, wspierając wypoczynek potrzebujących dzieci  i młodzieży </p>
              </div>
            </b-col>
          </b-row>
          <b-row style="margin-top: 52px; margin-bottom: 80px">
            <b-col>
              <a
                :href="data.chromeExtensionUrl"
                @click="thankyou = true"
                target="_blank"
              >
                <button class="solid">
                  Przeglądaj Apartamenty Karpacz
                </button></a
              >
            </b-col>
          </b-row>
        </b-container>
      </section>


      <section v-else>
        <b-container class="text-center" ref="howitworks">
          <b-row>
            <b-col>
              <h2  style="margin-bottom: 80px" v-if="data.subKey == 'sunseasons'">3 proste kroki, by pomóc</h2>
              
              <h2 style="margin-top: 80px" v-else>
                Wystarczą 3 proste kroki<br />do wsparcia
              </h2>
              <p style="margin-bottom: 50px">
                Pomaganie z Altroo jest banalnie proste!<br />Gromadzimy
                wartościowe, ale anonimowe dane, które wymieniamy na fundusze
                dla wsparcia działań charytatywnych.
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4" sm="12">
              <div class="step">
                <img alt="Donateo" src="./assets/step1.svg" />
                <h4>Zainstaluj rozszerzenie Altroo</h4>
              </div>
            </b-col>
            <b-col md="4" sm="12">
              <div class="step">
                <img alt="Donateo" src="./assets/step2.svg" />
                <h4>Korzystaj z przeglądarki jak do tej pory</h4>
              </div>
            </b-col>
            <b-col md="4" sm="12">
              <div class="step">
                <img alt="Donateo" src="./assets/step3.svg" />
                <h4>
                  Dochód z reklam i zakupów online będzie przekazywany na
                  wsparcie wybranego przez Ciebie celu organizacji dobroczynnej
                </h4>
              </div>
            </b-col>
          </b-row>
          <b-row style="margin-top: 52px; margin-bottom: 80px">
            <b-col>
              <a
                :href="data.chromeExtensionUrl"
                @click="thankyou = true"
                target="_blank"
              >
                <button class="solid">
                  <img alt="Donateo" src="./assets/download.svg" />Pobierz
                  Altroo i zacznij pomagać
                </button></a
              >
            </b-col>
          </b-row>
        </b-container>
      </section>
      <!--
      <section class="opinions" ref="opinions">
        <b-container>
          <b-row>
            <b-col v-for="opinion in data.opinions" :key="opinion.id">
              <div class="opinion text-center shadow3">
                <img alt="Donateo" src="./assets/apostrophe.svg" />
                <p>
                  {{ opinion.content }}
                </p>
                <h6>{{ opinion.author }}</h6>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </section>
    -->
      <section class="faq" ref="faq">
        <b-container>
          <b-row>
            <b-col md="8" offset-md="2" sm="12" offset-sm="0">
              <h2 class="text-center" style="margin-bottom: 60px">
                Często zadawane pytania
              </h2>
              <!--
              <div
                v-for="item in data.faqItems"
                :key="item.question"
                class="ac-item"
                :class="item.isOpen ? 'open shadow3' : null"
              >
                <div
                  class="ac-item--header"
                  :class="item.isOpen ? null : 'collapsed'"
                  :aria-expanded="item.isOpen ? 'true' : 'false'"
                  :aria-controls="item.question"
                  @click="item.isOpen = !item.isOpen"
                >
                  <span class="arrow"
                    ><img alt="Donateo" src="./assets/arrow.svg"
                  /></span>
                  <h5>{{ item.question }}</h5>
                </div>
                <b-collapse
                  :id="item.question"
                  v-model="item.isOpen"
                  class="mt-2"
                >
                  <div class="ac-item--body">{{ item.answer }}</div>
                </b-collapse>
              </div>
              -->
              <div
                v-for="item in data.faqItems"
                :key="item.question"
                class="ac-item"
                :class="item.isOpen ? 'open shadow3' : null"
              >
                <div
                  class="ac-item--header"
                  :class="item.isOpen ? null : 'collapsed'"
                  :aria-expanded="item.isOpen ? 'true' : 'false'"
                  :aria-controls="item.title"
                  @click="item.isOpen = !item.isOpen"
                >
                  <span class="arrow"
                    ><img alt="Donateo" src="./assets/arrow.svg"
                  /></span>
                  <h5>{{ item.question }}</h5>
                </div>
                <b-collapse :id="item.title" v-model="item.isOpen" class="mt-2">
                  <div class="ac-item--body">{{ item.answer }}</div>
                </b-collapse>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </section>

      

      <section class="download sunseason" v-if="data.subKey == 'sunseasons'">
        <b-container>
          <b-row align-v="center" style="position: relative">
            <b-col md="6" sm="12">
              <div style="margin: 40px 0">
                <h2>Twój relaks w Karpaczu = wypoczynek dla potrzebujących dzieci</h2>
                <a
                  :href="data.chromeExtensionUrl"
                  @click="thankyou = true"
                  target="_blank"
                >
                  <button class="solid">Apartamenty Karpacz
                  </button>
                </a>
              </div></b-col
            >
            <b-col style="position: relative; height: 383px" md="6" sm="12"
              ><img alt="Donateo" src="./assets/OBJECTS.png"
            /></b-col>
          </b-row>
        </b-container>
      </section>

      <section class="download" v-else>
        <b-container>
          <b-row align-v="center" style="position: relative">
            <b-col md="6" sm="12">
              <div style="margin: 40px 0">
                <h2>Pobierz Altroo i zacznij pomagać już dziś</h2>
                <a
                  :href="data.chromeExtensionUrl"
                  @click="thankyou = true"
                  target="_blank"
                >
                  <button class="solid">
                    <img alt="Donateo" src="./assets/download.svg" />Pobierz
                    Altroo
                  </button>
                </a>
              </div></b-col
            >
            <b-col style="position: relative; height: 383px" md="6" sm="12"
              ><img alt="Donateo" src="./assets/obj.webp"
            /></b-col>
          </b-row>
        </b-container>
      </section>
      <section class="seo">
        <b-container>
          <b-row align-v="center" style="position: relative; font-size: 14px">
            <b-col cols="12">
              <p>
                <strong>{{ data.seoTitle }}</strong>
              </p>
              <p>{{ data.seoContent }}</p>
              <p v-if="data.metaKeywords && data.metaKeywords.includes(',')">
                <span
                  v-for="tag in data.metaKeywords.split(',')"
                  :key="tag"
                  class="tags"
                >
                  {{ tag }}
                </span>
              </p>
            </b-col>
          </b-row>
          <hr />
        </b-container>
      </section>

    </div>

    <div v-if="showBlog && data.foundationName == 'Fundacja 2xKochaj'">
      <div class="container">

        <p>26-10-2023</p>

<h3>Wspieraj dzieci z Fundacji 2xKochaj razem z Altroo </h3>

<h4>
W dzisiejszych czasach internet staje się potężnym narzędziem do rozwiązywania problemów społecznych i niesienia pomocy. Dla wielu z nas przeglądanie sieci to codzienna rutyna, ale czy wiesz, że możesz pomagać innym, korzystając z rozszerzenia Altroo? W tym artykule dowiesz się, jak używając tego prostego narzędzia, możesz wspierać dzieci z ubogich rodzin, zwłaszcza na Śląsku, za pośrednictwem Fundacji 2xKochaj używając bezpłatnej wtyczki Altroo dla Chrome.
Altroo to rozszerzenie przeglądarki internetowej Chrome, które pozwala użytkownikom pomagać organizacjom charytatywnym bez wydawania dodatkowych środków finansowych. Działa to bardzo prosto - wystarczy zainstalować Altroo, a następnie korzystając z przeglądarki, jak zawsze generujesz fundusze dla potrzebujących. W przypadku Fundacji 2xKochaj jest to wyjątkowa okazja, by wspierać dzieci z ubogich rodzin ze Śląska, którzy potrzebują naszej pomocy.
</h4>

<strong>Projekty Fundacji 2xKochaj</strong>
<p>
Fundacja 2xKochaj to organizacja działająca na rzecz dzieci z ubogich rodzin, szczególnie na terenie Mysłowic oraz w innych miejscowościach na Śląsku. Odwiedźmy stronę internetową fundacji (https://2xkochaj.pl/projekty/) i zobacz, jakie projekty są dostępne do wsparcia. Oto kilka przykładów:
</p>
<ol>
<li>Projekt koncentruje się na dostarczaniu artykułów pierwszej potrzeby, ubrań i prezentów dla dzieci. Korzystając z Altroo, możemy pomóc w zebraniu funduszy na zakup tych potrzebnych przedmiotów.
</li>
<li>Wspierając ten projekt, można pomóc dzieciom zdobywać wiedzę i umiejętności poprzez dofinansowanie zajęć edukacyjnych. Altroo pozwala na regularne przekazywanie środków na rozwijanie edukacji dzieci ze Śląska.
 </li>
<li> Dzieci z rodzin ubogich często nie mają możliwości wyjazdu na wakacje. Wspierając ten projekt, możemy pomóc zorganizować wyjazdy, które na zawsze pozostaną w sercach tych dzieci.
</li>
</ol>
<strong>Jak pomaga Altroo?</strong>
<p>Altroo działa na zasadzie partnerstwa z firmami, które płacą za wyświetlanie reklam na stronach internetowych użytkowników. Część z tych środków przekazywana jest na cele charytatywne, takie jak projekty Fundacji 2xKochaj. Instalując Altroo i przeglądając internet, generujesz fundusze, które pomagają dzieciom z ubogich rodzin na Śląsku.
</p>
<strong>Jak zacząć pomagać?</strong>

<ol>


<li><strong>Pobierz Altroo</strong>  - Rozpocznij od pobrania i zainstalowania rozszerzenia Altroo do swojej przeglądarki.
</li><li><strong>Przeglądaj internet</strong> - Teraz możesz korzystać z internetu jak zawsze, wiedząc, że każda Twoja przeglądarkowa aktywność przyczynia się do pomocy potrzebującym.
</li><li><strong>Wsparcie finansowe</strong> - Dzięki Altroo środki zostaną przekazane na wybrane przez Ciebie projekty automatycznie, bez dodatkowych działań z Twojej strony.
</li>
</ol>
<p>
Pomaganie dzieciom z ubogich rodzin na Śląsku stało się teraz jeszcze łatwiejsze dzięki Altroo. Dzięki temu rozszerzeniu możemy czynić dobro w trakcie codziennego przeglądania internetu, nie wydając dodatkowych pieniędzy. Zainstaluj Altroo, wybierz projekty Fundacji 2xKochaj do wsparcia, i razem zmienimy życie tych dzieci na lepsze. Twoje rozszerzenie, to nasza  dobra robota! Akcja prowadzona jest razem z Fundacją Donateo.
</p>
<strong>Program PIT, płatność przez sklep online, wolontariat lub datek - pomagać możesz na wiele sposobów</strong>

<p>Jeśli szukasz dodatkowych opcji wsparcia naszej fundacji skorzystaj z jednej lub więcej opcji wsparcia: </p>

<ul>

<li><strong>Darowizna dla dzieci.</strong> Wpłać pieniądze na konto nr: 55 1050 1214 1000 0090 3064 7805 tytułem: „darowizna na cele statutowe”
</li><li><strong>Wsparcie materialne.</strong> Zbieramy: przybory szkolne, żywność, słodycze oraz napoje
</li><li><strong>Wolontariat.</strong>
</li><li><strong>Program PIT.</strong> Rozliczenie 1,5% np. poprzez e-pity z możliwością odliczenia ich od podatku. W odpowiedniej rubryce rozliczenia należy wpisać  KRS Fundacji 2xKochaj, czyli 000548849 należy wpisać w odpowiednią rubrykę w np. darmowym programie do PIT 37 do rozliczenia lub inny PIT do wypełnienia np. program PIT 28. Wypełnij pit 36 - Poltax PIT 28 online lub każdą inną e-deklarację jako darmowy program do pobrania.
</li><li><strong>Mikrodonacja</strong> poprzez <strong>Przelewy24</strong>. Sprawdź możliwość wsparcia fundacji poprzez sklep online. 
</li>
</ul>

<p> Nazwa Fundacji „2xKOCHAJ” została zaczerpnięta ze słów Ewangelii Mateusza 22, 32-39 „Będziesz miłował Pana, Boga swego, z całego serca swego i z całej duszy swojej, i z całej myśli swojej.
</p>
<hr>



<p>27-10-2023</p>
<h3>Fundacja 2x Kochaj: Wspieraj młodzież w samoakceptacji i aktywności</h3>

<p>Fundacja 2x Kochaj to organizacja, która od lat działa na rzecz potrzebujących dzieci, oferując im wsparcie w różnych dziedzinach życia. Dążymy do tego, aby pomagać dzieciom w pełnym zakresie, wspierając ich rozwój emocjonalny, społeczny i fizyczny. Ostatnio przeprowadziliśmy kilka ciekawych działań, którymi chcielibyśmy się z Wami podzielić.
</p>

<strong>
Warsztaty SAMOAKCEPTACJI</strong>

<p>Jednym z najważniejszych celów naszej Fundacji jest pomoc dzieciom w budowaniu pewności siebie, samoakceptacji i pozytywnego postrzegania siebie. Ostatnio mieliśmy przyjemność spotkać się z młodzieżą na warsztatach samoakceptacji. Cieszy nas, że każdorazowo przybywa coraz więcej uczestników, którzy chcą pracować nad swoim rozwojem emocjonalnym. Warsztaty rozpoczęły się od otwartej rozmowy na temat zmian w postrzeganiu siebie i świata przez uczestników. Każdy z nich mógł zastanowić się, jakie zmiany zaszły w ciągu ostatniego czasu i czy ćwiczenia z poprzedniego spotkania wykonaliby teraz tak samo. To doskonały sposób na refleksję nad własnym rozwojem.
</p><p>
  Następnie przeszliśmy do ćwiczenia polegającego na obserwowaniu myśli związanych z tym, jak każdy z nas widzi samego siebie, zaczynających się od słów "JESTEM…". Nawet pozornie negatywne określenia można zamienić na pozytywne, odnajdując w sobie jasne strony. Kolejnym etapem była praca w parach, gdzie uczestnicy mieli okazję doświadczyć, jak reagują na słowa i zachowania innych, oraz jakie uczucia to w nich wywołuje. To ważna lekcja w kwestii samoakceptacji i zrozumienia, jak ważne jest docenianie i wartościowanie innych. Na zakończenie spotkania skupiliśmy się na wdzięczności, by każdy mógł podzielić się swoimi obserwacjami i refleksjami. To były wyjątkowe warsztaty, które z pewnością przyniosły wiele korzyści uczestnikom.
</p>
<strong>Aktywność i Radość</strong>
<p>
Nie tylko praca nad rozwojem osobistym jest ważna. Aktywność fizyczna i dobra zabawa również mają ogromne znaczenie dla rozwoju młodych osób. Dlatego w miniony piątek zorganizowaliśmy zabawy sportowe na sali gimnastycznej, które dostarczyły mnóstwo radości i śmiechu. Po intensywnym popołudniu przyszedł czas na coś, co uwielbiamy jeszcze bardziej — pyszną pizzę! Nasza własnoręcznie przygotowana pizza zawsze jest absolutnie nie do pobicia. To również okazja do integracji i spędzenia czasu razem.
</p><p>
Warto podkreślić, że wszystkie te działania były możliwe dzięki współpracy z Urzędem Miasta Mysłowice oraz organizacjami takimi jak Pajacyk — Polska Akcja Humanitarna. To dzięki nim możemy kontynuować naszą misję wsparcia dzieci i młodzieży.
</p>




<strong>Twoje Wsparcie z Altroo</strong>

<p>
Chcemy podkreślić, że Twoje wsparcie jest nieocenione dla naszej Fundacji. Dlatego zachęcamy do korzystania z Altroo — darmowej wtyczki, która pozwala na przekazywanie darowizn na nasze działania. To prosty sposób, aby pomóc potrzebującym dzieciom i dać im nadzieję na lepsze jutro. Korzystając z Altroo, masz możliwość zmieniania życia razem z nami. Twoje wsparcie to zmiana w życiu dzieci, to pomoc teraz i nadzieja na przyszłość. Pobierz teraz Altroo i dołącz do grona tych, którzy chcą czynić dobro! Fundacja 2xKochaj kontynuuje swoją misję wsparcia potrzebujących dzieci i młodzieży. Wspólnie możemy uczynić świat lepszym miejscem, dając dzieciom szansę na rozwój i szczęśliwe życie.
</p>
<strong>Daj dzieciom nadzieję!</strong>
<p>
Korzystaj z programu do rozliczenia PIT 37 2021 i kolejnych lat oraz e-deklaracji PIT, aby przekazać 1,5% podatku na rzecz Fundacji 2xKochaj. Razem możemy zmieniać życie dzieci! Dziękujemy za wsparcie! 
</p>
<p><i>
Dla lepszego jutra dzieci - działaj już dziś! Pobierz Altroo i wspieraj. Razem możemy więcej! Pomóż teraz! Pobierz Altroo i zmieniaj świat dzieci. Zobacz więcej! Wsparcie dzieci z Altroo. Altroo - Twoja pomoc. Altroo - darmowa pomoc. Pomagaj z Altroo. Szukaj z Altroo - pomóż dzieciom. Dziel się Altroo. Wspieraj z Altroo. Daj dzieciom szansę. Pomagaj z Altroo. Altroo - dobra robota!
</i></p>

<hr>
<p>27-10-2023</p>

<h3>Fundacja 2xKochaj: Wspieraj dzieci poprzez wiedzę, sport i dobrą kuchnię</h3>

<p>Fundacja 2xKochaj to organizacja, która ma na celu wsparcie potrzebujących dzieci i ich rodzin. Działa ona na wielu frontach, aby poprawić jakość życia tych, którzy jej wsparcia potrzebują. Jednym z narzędzi, które pozwalają na osiągnięcie tego celu, jest rozliczenie podatku PIT na rzecz Fundacji 2xKochaj. Dzięki programowi do rozliczenia PIT 37 2021 oraz innych narzędziom, każdy z nas może dokonać odpisu darowizny na rzecz dzieci potrzebujących. To niesamowite, że już podczas rozliczenia podatku możemy włączyć się w pomoc innym!
</p><p>
Fundacja 2xKochaj działa nie tylko wirtualnie, ale także w realnym świecie. Ostatnio mieliśmy przyjemność uczestniczyć w kilku wyjątkowych wydarzeniach, które nie tylko dostarczyły dzieciom wiedzy, ale także zapewniły im radość i niezapomniane chwile. Chcielibyśmy podzielić się z Wami kilkoma z tych wspaniałych momentów.
</p>
<strong>Zdrowe odżywianie z Panią Dietetyk</strong>
<p>
Mieliśmy okazję zaprosić Panią Dietetyk, mgr Adriannę Barczyńską, która prowadzi konsultacje online w Tychach i Mysłowicach. Pani Dietetyk podzieliła się z nami cennymi wskazówkami na temat zdrowego odżywiania. Wspólnie z młodszymi dziećmi przygotowaliśmy przepyszne bagietki z masłem w dwóch różnych smakach, a starsze dzieci stanęły przed bardziej zaawansowanym wyzwaniem i przygotowały pyszny bigos z cukinii. To nie tylko smakowało wyśmienicie, ale także nauczyło dzieci, jak wykorzystywać zdrowe składniki w kuchni. 
</p>
<strong>Sportowe zajęcia z Ewą Bień</strong>
<p>
Sport odgrywa ogromną rolę w rozwoju dzieci. Dlatego mieliśmy przyjemność gościć wspaniałą instruktorkę, Ewę Bień. Zajęcia sportowe to nie tylko sposób na poprawę kondycji fizycznej, ale także na rozwijanie zdrowej rywalizacji, umiejętności radzenia sobie ze stresem, koncentrację i współpracę zespołową. Po intensywnych zajęciach sportowych, dzieci razem przygotowały pyszną kolację. Na stole zagościł tortilla z kurczakiem i warzywami.
</p>
<strong>Powrót po wakacyjnej przerwie</strong>
<p>
Po wakacyjnej przerwie mieliśmy okazję spotkać się z 40-osobową grupą dzieci.~Pogoda nam sprzyjała, więc zorganizowaliśmy ogrodową integrację. Nasi mali artyści malowali, rysowali i tworzyli prace plastyczne. Aby wykorzystać ciepłe popołudnie, zorganizowaliśmy także gry i zabawy sportowe. Oczywiście nie mogło zabraknąć wspólnego gotowania. Na stole zagościły naleśniki i gofry z owocami.
</p><p>
Fundacja 2xKochaj działa nie tylko na polu edukacji, ale także w obszarze kultury, sportu i rozwoju osobistego dzieci. Współpracujemy z Urzędem Miasta Mysłowice, co pozwala nam na jeszcze efektywniejsze działania.
</p><p>
Twoje wsparcie z Altroo to zmiana w życiu dzieci. Altroo to darmowa wtyczka, która pozwala dokonać darowizny podczas korzystania z przeglądarki.  Razem możemy więcej, dlatego zachęcamy do pobrania Altroo i dołączenia do akcji wspierania dzieci w ramach Fundacji 2xKochaj. Dzielmy się dobrem, wspierajmy dzieci i dajmy im szansę na lepsze jutro! Zmieniajmy życie razem! Pobierz Altroo i pomóż już dziś. Twoje wsparcie to nadzieja, a Altroo to narzędzie do jej dawania. Włącz się i wspieraj dzieci z Altroo już teraz!
</p>
<hr>
        
      </div>
    
    
    </div>

    </div>
    <footer>
      <b-container>
        <b-row class="footer-body">
          <b-col md="4" sm="12" class="footer-item" v-if="data">
            <div class="footer-header">
              <img alt="Donateo" :src="data.foundationLogoUrl" />
            </div>
            <p class="footer-title">{{ data.foundationName }}</p>
            <a :href="data.aboutFoundationUrl" target="_blank">O fundacji</a>
            <a :href="data.foundationStatuteUrl" target="_blank">Statut</a>
            <a :href="data.foundationMissionUrl" target="_blank">Misja</a>
            <a :href="data.foundationContactUrl" target="_blank">Kontakt</a>
            <a v-if="data.foundationName == 'Fundacja 2xKochaj'" href="#" @click="showBlog = true">Blog</a>
          </b-col>

          <b-col md="4" sm="12" class="footer-item"  v-if="data.subKey == 'sunseasons'">
            <div class="footer-header">
              <img alt="Donateo" src="./assets/ss24logo.svg" />
            </div>
            <p class="footer-title">Sun Seasons 24</p>
            <a href="#howitworks" @click="scrollMeTo('howitworks')"
              >Jak to działa</a
            >
            <a href="https://www.facebook.com/sunseasons24/reviews">Opinie</a>
            <a href="#why" @click="scrollMeTo('why')">Dlaczego warto</a>
            <a href="https://sunseasons24.pl/pl/o-firmie">O firmie</a>
            <a href="https://sunseasons24.pl/pl/kontakt">Kontakt</a>
            
          </b-col>

          <b-col md="4" sm="12" class="footer-item" v-else>
            <div class="footer-header">
              <img alt="Donateo" src="./assets/footer-altroo.svg" />
            </div>
            <p class="footer-title">Altroo</p>
            <a href="#howitworks" @click="scrollMeTo('howitworks')"
              >Jak to działa</a
            >
            <a href="#why" @click="scrollMeTo('why')">Dlaczego warto</a>
            <a href="#opinions" @click="scrollMeTo('opinions')">Opinie</a>
            <a href="#faq" @click="scrollMeTo('faq')">FAQ</a>
            
          </b-col>



          <b-col md="4" sm="12" class="footer-item">
            <div class="footer-header">
              <img alt="Donateo" src="./assets/footer-donateo.svg" />
            </div>
            <p class="footer-title">Donateo</p>
            <a
              href="https://donateo.pl/darczyncy/jak-pomagac-z-donateo/"
              target="_blank"
              >Jak pomagać z Donateo</a
            >
            <a
              href="https://donateo.pl/organizacje/dolacz-do-donateo/"
              target="_blank"
              >Dołącz do Donateo</a
            >
            <a href="https://donateo.pl/kontakt/" target="_blank">Kontakt</a>
          </b-col>
        </b-row>
        <b-row class="bottom-footer">
          <b-col md="4" sm="12"
            ><p class="copyright">Copyrights by Donateo © 2023</p></b-col
          >
          <b-col md="4" sm="12" class="item"
            ><a
              href="https://donateo.pl/wp-content/uploads/2022/08/rodo_BetterPOS.pdf"
              target="_blank"
              >RODO</a
            >
            <a
              href="https://donateo.pl/wp-content/uploads/2022/08/regulamin_Donateo.pdf"
              target="_blank"
              >Regulamin</a
            >
            <a
              href="https://donateo.pl/wp-content/uploads/2022/08/pp_BetterPOS.pdf"
              target="_blank"
              >Polityka prywatności</a
            >
          </b-col>
          <b-col md="4" sm="12" class="item-last">
            <p>Odwiedź nas na:</p>
            <a
              :href="data.facebook"
              v-if="data.facebook != null"
              target="_blank"
              ><img alt="Facebook" src="./assets/facebook.svg"
            /></a>
            <a
              :href="data.instagram"
              v-if="data.instagram != null"
              target="_blank"
              ><img alt="Instagram" src="./assets/instagram.svg"
            /></a>
            <a :href="data.twitter" v-if="data.twitter != null" target="_blank"
              ><img alt="Twitter" src="./assets/twitter.svg"
            /></a>
            <a
              :href="data.linkedIn"
              v-if="data.linkedIn != null"
              target="_blank"
              ><img alt="Linkedin" src="./assets/linkedin.svg"
            /></a>
          </b-col>
        </b-row>
      </b-container>
    </footer>
    <div class="cookie-info" v-if="!cookies">
      <b-container>
        <b-row>
          <b-col cols="12" class="cookie-info-container">
            <p>
              Wykorzystujemy ciasteczka do badania ruchu na stronie i innych
              funkcjonalności. Więcej informacji na ten temat można znaleźć na
              stronie
              <a
                href="https://donateo.pl/wp-content/uploads/2022/08/pp_BetterPOS.pdf"
                target="_blank"
                >Polityka Prywatności.</a
              >
            </p>
            <button @click.prevent="setCookies()">Zgoda</button>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import LiteYouTubeEmbed from "vue-lite-youtube-embed";
import "vue-lite-youtube-embed/style.css";

export default {
  name: "App",
  components: {
    LiteYouTubeEmbed,
  },
  data() {
    return {
      cookies: false,
      thankyou: false,
      max: 100,
      domain: null,
      subdomain: null,
      visible: false,
      faqItems: [
        {
          title: "Jak działa Altroo Extension?",
          text: "Rozszerzenie Altroo Extension zmienia domyślną wyszukiwarkę na Altroo.net. Kiedy korzystasz z Internetu, czyli odwiedzasz strony czy aplikacje internetowe, oglądasz i klikasz w reklamy, ich dostawcy dzielą się z nami swoimi przychodami. 80% tych środków przekazujemy na wybraną przez Ciebie organizację. Pozostałą część przekazujemy na pokrycie kosztów operacyjnych budowania i rozwijania Altroo.",
          isOpen: false,
        },

        {
          title: "Czy jest wersja mobilna Altroo?",
          text: "Jeszcze nie, wersja ta jest dopiero w planie. Odwiedź Altroo.net, aby być na bieżąco. Pomimo braku wersji mobilnej istnieje możliwość synchronizacji haseł z telefonem na iOS oraz Android.",
          isOpen: false,
        },
        {
          title: "Jak synchronizować hasła i zakładki?",
          text: "Synchronizacji haseł można dokonać za pomocą rozszerzeń takich jak Bitwarden (darmowy), 1Password (płatny), Lastpass (darmowy) lub Dashlane (darmowy). Zakładki można automatycznie przenieść przy instalacji przeglądarki. Jeżeli chcesz przenieść zakładki już po instalacji, możesz użyć do tego rozszerzeń takich jak np. EverSync (darmowy).",
          isOpen: false,
        },
        {
          title: "Jakie organizacje mogę wspierać i czy są one wiarygodne?",
          text: "Możesz wybrać organizację dobroczynną i dowolny cel z naszej listy. Cele organizacji możesz wybrać powyżej na bieżącej stronie a także pod adresem: https://altroo.net/pl/goals/ lub po kliknięciu w frazę „goals” w prawym górnym rogu nowo otwartej karty w przeglądarce. Wszystkie organizacje na naszej liście są zweryfikowane i wiarygodne.",
          isOpen: false,
        },
        {
          title: "Czy używanie Adblocka blokuje wsparcie?",
          text: "Niestety tak. Dochód dla organizacji pochodzi przede wszystkim z reklam i linków sponsorowanych. Jeśli ich nie widzisz lub wyświetlają Ci się one  bardzo rzadko, o tyle mniej też środków będziemy mogli przekazywać na wybrany przez Ciebie cel.",
          isOpen: false,
        },
      ],
      accordion: [
        {
          title: "Projekt polski",
          text: "Altroo to pierwszy taki w Polsce projekt oferujący dobroczynne rozszerzenie do przeglądarki. Projekt ten powstał w celu umożliwienia podjęcia prostych działań charytatywnych przy okazji standardowego korzystania z sieci.",
          isOpen: false,
        },
        {
          title: "Z nami pomagasz z każdego miejsca na świecie!",
          text: "Wszyscy możemy sprawić, że świat stanie się lepszym miejscem. Dlatego umożliwiamy pomaganie z każdego jego zakątka! Altroo to rozszerzenie, które działa globalnie i daje prostą możliwość niesienia pomocy potrzebującym.",
          isOpen: false,
        },
        {
          title: "Wspieranie organizacji charytatywnych",
          text: "Zależało nam na połączeniu użyteczności z realną możliwością niesienia pomocy. Użytkownicy podczas korzystania z Altroo wspierają wybraną przez siebie fundację, na którą nasz zespół Altroo przekazuje darowizny.",
          isOpen: false,
        },
        {
          title: "Łatwość użytkowania",
          text: "Rozszerzenie Altroo zostało zaprojektowane z myślą o najwyższej jakości użytkowania. Korzystasz z przeglądarki z Altroo Extension w sposób prosty i intuicyjny i jednocześnie pomagasz! ",
          isOpen: false,
        },
        {
          title: "Darowizny z każdego wyszukiwania",
          text: "Każdy użytkownik Altroo sam decyduje jaką organizację charytatywną będzie wspierać. Aktywność w sieci jest monetyzowana, a zebrane środki są przekazywane na wybraną przez Ciebie fundację. Warto podkreślić, że przekazywane są jedynie anonimowe dane statystyczne, aby zapewnić pełną prywatność i bezpieczeństwo użytkowników.",
          isOpen: false,
        },
      ],
      isLoading: false,
      data: null,
      key: new URL(location.href).searchParams.get("key"),
      playing: false,
      showBlog: false
    };
  },
  metaInfo() {
    if (this.data) {
      return {
        title: this.data.metaTitle,
        description: this.data.metaDescription,
        meta: [
          { property: "og:title", content: this.data.ogTitle },
          { property: "og:type", content: "website" },
          { property: "og:url", content: location.hostname }, // here it is just ngrok for my test
          { property: "og:description", content: this.data.ogDescription },
          {
            property: "og:image",
            content: "",
          },
        ],
      };
    }
  },
  created() {},
  mounted() {
    this.getData();
    if (window.location.pathname.includes("blog")) {
      this.showBlog = true;
    }
  },
  computed: {},
  methods: {
    setCookies() {
      this.cookies = true;
      this.$cookies.set("cookieAccept", true);
    },
    getData() {
      this.isLoading = true;
      this.cookies = this.$cookies.get("cookieAccept");

      let domain = window.location.hostname;
      if (domain.includes("://")) {
        domain.split("://")[1];
      }
      var subdomain = domain.split(".")[0];
      console.log(subdomain);

      axios
        .get(
          "https://prod-poseidon-paywall-cp-api.azurewebsites.net/Get/" +
            subdomain
        )
        .then((res) => {
          this.data = res.data;
          this.isLoading = false;
        })
        .catch((err) => {
          this.getData();
          console.log(err.data);
        });
    },
    calcPercentage(x, y) {
      return (100 * x) / y;
    },
    switchAccordion: function (event) {
      let el = event.target;
      this.isOpen = !this.isOpen; // switch data isOpen
      if (this.isOpen) {
        let childEl1 = el.childNodes[1];
        el.style.height = childEl1.style.height;
      }
    },
    scrollMeTo(refName) {
      
      this.showBlog = false;
      setTimeout(()=> 
      {
        this.visible = false;
      var element = this.$refs[refName];
      var top = element.offsetTop;

      window.scrollTo(0, top);
      }, 200); 

    },
  },
};
</script>
<style>
article {
  height: 424px;
  width: 100%;
}

.tags {
  margin-right: 5px;
  margin-top: 5px;
  background: #f0f0f0;
  display: inline-block;
  border-radius: 10px;
  padding: 4px 10px;
}
</style>